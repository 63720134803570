/**
*
* WARNING : fichier généré par forms, ne pas le modifier directement
*
**/


import ShowTiers from '@@formsHandlers/declareSinistreIncapacite/ShowTiers';

import ShowDetailReason from '@@formsHandlers/declareSinistreIncapacite/ShowDetailReason';

import ShowPoliceInvestigation from '@@formsHandlers/declareSinistreIncapacite/ShowPoliceInvestigation';

import ShowTiersResponsible from '@@formsHandlers/declareSinistreIncapacite/ShowTiersResponsible';


const formData = [

{
name: 'title',
required: true,
input: 'select',
source: 'titles',
label: "Civilité",
placeholder: "Civilité",



handlers: {
visible: undefined
}
},

{
name: 'birthname',
required: true,
input: 'text',
source: '__UNDEFINED__',
label: "Nom de naissance",
placeholder: "__UNDEFINED__",



handlers: {
visible: undefined
}
},

{
name: 'firstname',
required: true,
input: 'text',
source: '__UNDEFINED__',
label: "Prénom(s)",
placeholder: "__UNDEFINED__",



handlers: {
visible: undefined
}
},

{
name: 'address',
required: true,
input: 'text',
source: '__UNDEFINED__',
label: "Adresse",
placeholder: "__UNDEFINED__",



handlers: {
visible: undefined
}
},

{
name: 'postal',
required: true,
input: 'text',
source: '__UNDEFINED__',
label: "Code postal",
placeholder: "__UNDEFINED__",



handlers: {
visible: undefined
}
},

{
name: 'city',
required: true,
input: 'text',
source: '__UNDEFINED__',
label: "Ville",
placeholder: "__UNDEFINED__",



handlers: {
visible: undefined
}
},

{
name: 'birthdate',
required: true,
input: 'date',
source: '__UNDEFINED__',
label: "Date de naissance",
placeholder: "__UNDEFINED__",



handlers: {
visible: undefined
}
},

{
name: 'nir',
required: true,
input: 'text',
source: '__UNDEFINED__',
label: "N° sécurité sociale",
placeholder: "__UNDEFINED__",



handlers: {
visible: undefined
}
},

{
name: 'startDate',
required: true,
input: 'date',
source: '__UNDEFINED__',
label: "Date d’entrée dans l’entreprise",
placeholder: "__UNDEFINED__",



handlers: {
visible: undefined
}
},

{
name: 'emploiCategory',
required: true,
input: 'select',
source: 'employType',
label: "Catégorie d’emploi",
placeholder: "Catégorie d’emploi",



handlers: {
visible: undefined
}
},

{
name: 'workStoppingReason',
required: true,
input: 'select',
source: 'workStoppingReasonType',
label: "Motif de l’arrêt de travail",
placeholder: "Motif de l’arrêt de travail",



handlers: {
visible: undefined
}
},

{
name: 'workStoppingDateStart',
required: true,
input: 'date',
source: '__UNDEFINED__',
label: "Premier jour de l'arrêt de travail",
placeholder: "__UNDEFINED__",



handlers: {
visible: undefined
}
},

{
name: 'workStoppingDateAskFrom',
required: true,
input: 'date',
source: '__UNDEFINED__',
label: "Absence faisant l'objet de la demande du",
placeholder: "__UNDEFINED__",



handlers: {
visible: undefined
}
},

{
name: 'workStoppingDateAskTo',
required: true,
input: 'date',
source: '__UNDEFINED__',
label: "au",
placeholder: "__UNDEFINED__",



handlers: {
visible: undefined
}
},

{
name: 'workRestartDate',
required: false,
input: 'date',
source: '__UNDEFINED__',
label: "Date de reprise du travail",
placeholder: "__UNDEFINED__",



handlers: {
visible: undefined
}
},

{
name: 'workRestartMethod',
required: false,
input: 'radio',
source: 'workRestartMethodType',
label: "Temps de travail à la reprise du travail",
placeholder: "__UNDEFINED__",



handlers: {
visible: undefined
}
},

{
name: 'trimesterPayBrut',
required: true,
input: 'number',
source: '__UNDEFINED__',
label: "Salaire trimestriel brut que l'assuré a perçu au cours du trimestre civil précédant l'arrêt de travail (hors primes, compléments de salaire, heures supplémentaires)",
placeholder: "__UNDEFINED__",



handlers: {
visible: undefined
}
},

{
name: 'primeAmount',
required: true,
input: 'number',
source: '__UNDEFINED__',
label: "Montant des primes, gratifications, compléments de salaire et heures supplémentaires versés au cours des 4 trimestres civils précédents l'arrêt de travail",
placeholder: "__UNDEFINED__",



handlers: {
visible: undefined
}
},

{
name: 'dateLeaving',
required: false,
input: 'date',
source: '__UNDEFINED__',
label: "Date de licenciement ou de démission du salarié",
placeholder: "__UNDEFINED__",



handlers: {
visible: undefined
}
},

{
name: 'accidentTiers',
required: false,
input: 'radio',
source: 'yesNoType',
label: "Si l’arrêt de travail est consécutif à un accident, a-t-il été causé par un tiers ?",
placeholder: "__UNDEFINED__",
columns: 2,


handlers: {
visible: undefined
}
},

{
name: 'dateAccident',
required: true,
input: 'date',
source: '__UNDEFINED__',
label: "Date de l'accident",
placeholder: "__UNDEFINED__",



handlers: {
visible: ShowTiers
}
},

{
name: 'reasonAccident',
required: true,
input: 'select',
source: 'accidentReason',
label: "Lieu de l'accident",
placeholder: "__UNDEFINED__",



handlers: {
visible: ShowTiers
}
},

{
name: 'reasonAccidentOther',
required: false,
input: 'text',
source: '__UNDEFINED__',
label: "Précisez",
placeholder: "__UNDEFINED__",



handlers: {
visible: ShowDetailReason
}
},

{
name: 'detailAccident',
required: true,
input: 'textarea',
source: '__UNDEFINED__',
label: "Circonstances détaillées de l'accident",
placeholder: "__UNDEFINED__",



handlers: {
visible: ShowTiers
}
},

{
name: 'genderTiers',
required: false,
input: 'select',
source: 'titles',
label: "Civilité",
placeholder: "Civilité",



handlers: {
visible: ShowTiers
}
},

{
name: 'birthnameTiers',
required: false,
input: 'text',
source: '__UNDEFINED__',
label: "Nom de naissance",
placeholder: "__UNDEFINED__",



handlers: {
visible: ShowTiers
}
},

{
name: 'firstnameTiers',
required: false,
input: 'text',
source: '__UNDEFINED__',
label: "Prénom(s)",
placeholder: "__UNDEFINED__",



handlers: {
visible: ShowTiers
}
},

{
name: 'addressTiers',
required: false,
input: 'text',
source: '__UNDEFINED__',
label: "Adresse",
placeholder: "__UNDEFINED__",



handlers: {
visible: ShowTiers
}
},

{
name: 'postalTiers',
required: false,
input: 'text',
source: '__UNDEFINED__',
label: "Code postal",
placeholder: "__UNDEFINED__",



handlers: {
visible: ShowTiers
}
},

{
name: 'cityTiers',
required: false,
input: 'text',
source: '__UNDEFINED__',
label: "Ville",
placeholder: "__UNDEFINED__",



handlers: {
visible: ShowTiers
}
},

{
name: 'societyTiers',
required: false,
input: 'text',
source: '__UNDEFINED__',
label: "La raison sociale de sa compagnie d’assurance",
placeholder: "__UNDEFINED__",



handlers: {
visible: ShowTiers
}
},

{
name: 'folderTiers',
required: false,
input: 'text',
source: '__UNDEFINED__',
label: "Le numéro de dossier auprès de sa compagnie d’assurance (si connu)",
placeholder: "__UNDEFINED__",



handlers: {
visible: ShowTiers
}
},

{
name: 'hasPoliceInvestigation',
required: false,
input: 'radio',
source: 'yesNoType',
label: "Une enquête de police ou de gendarmerie a-t-elle eu lieu ?",
placeholder: "__UNDEFINED__",



handlers: {
visible: ShowTiers
}
},

{
name: 'procesVerbal',
required: false,
input: 'text',
source: '__UNDEFINED__',
label: "Numéro de procès verbal",
placeholder: "__UNDEFINED__",



handlers: {
visible: ShowPoliceInvestigation
}
},

{
name: 'hasResponsibleTiers',
required: false,
input: 'radio',
source: 'yesNoType',
label: "Y-a-t-il eu un tiers responsable",
placeholder: "__UNDEFINED__",



handlers: {
visible: ShowTiers
}
},

{
name: 'assurSociety',
required: false,
input: 'text',
source: '__UNDEFINED__',
label: "Raison sociale de l'assureur tiers",
placeholder: "__UNDEFINED__",



handlers: {
visible: ShowTiersResponsible
}
},

{
name: 'assurAddress',
required: false,
input: 'text',
source: '__UNDEFINED__',
label: "Adresse de l'assureur tiers",
placeholder: "__UNDEFINED__",



handlers: {
visible: ShowTiersResponsible
}
},

{
name: 'assurPostal',
required: false,
input: 'text',
source: '__UNDEFINED__',
label: "Code postal de l'assureur tiers",
placeholder: "__UNDEFINED__",



handlers: {
visible: ShowTiersResponsible
}
},

{
name: 'assurCity',
required: false,
input: 'text',
source: '__UNDEFINED__',
label: "Ville de l'assureur tiers",
placeholder: "__UNDEFINED__",



handlers: {
visible: ShowTiersResponsible
}
},

{
name: 'rib',
required: false,
input: 'select',
source: 'ribs',
label: "RIB de l’entreprise",
placeholder: "__UNDEFINED__",



handlers: {
visible: undefined
}
},

{
name: 'attachments',
required: false,
input: 'files',
source: 'listAttachments',
label: "__UNDEFINED__",
placeholder: "Ajouter des pièces jointes",



handlers: {
visible: undefined
}
},

];

export default formData;