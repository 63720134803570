/**
*
* WARNING : fichier généré par forms, ne pas le modifier directement
*
**/



const formData = [

{
name: 'sinistre',
required: true,
input: 'select',
source: 'sinistres',
label: "Type de sinistre",
placeholder: "Type de sinistre",



handlers: {
visible: undefined
}
},

];

export default formData;