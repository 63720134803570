/**
*
* WARNING : fichier généré par forms, ne pas le modifier directement
*
**/



const formData = [

{
name: 'firstname',
required: true,
input: 'text',
source: '__UNDEFINED__',
label: "Prénom",
placeholder: "Prénom",



handlers: {
visible: undefined
}
},

{
name: 'lastname',
required: true,
input: 'text',
source: '__UNDEFINED__',
label: "Nom",
placeholder: "Nom",



handlers: {
visible: undefined
}
},

{
name: 'email',
required: true,
input: 'text',
source: '__UNDEFINED__',
label: "Email",
placeholder: "Email",



handlers: {
visible: undefined
}
},

{
name: 'openning_date',
required: true,
input: 'date',
source: '__UNDEFINED__',
label: "Date d'ouverture du compte",
placeholder: "Date d'ouverture du compte",



handlers: {
visible: undefined
}
},

];

export default formData;