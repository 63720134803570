/**
*
* WARNING : fichier généré par forms, ne pas le modifier directement
*
**/



const formData = [

{
name: 'message',
required: true,
input: 'textarea',
source: '__UNDEFINED__',
label: "Votre message",
placeholder: "__UNDEFINED__",



handlers: {
visible: undefined
}
},

];

export default formData;