/**
*
* WARNING : fichier généré par forms, ne pas le modifier directement
*
**/


import isForInsured from '@@formsHandlers/declareSinistreDeces/isForInsured';

import hasMin1Child from '@@formsHandlers/declareSinistreDeces/hasMin1Child';

import hasMin2Child from '@@formsHandlers/declareSinistreDeces/hasMin2Child';

import hasMin3Child from '@@formsHandlers/declareSinistreDeces/hasMin3Child';

import hasMin4Child from '@@formsHandlers/declareSinistreDeces/hasMin4Child';

import isNotForInsured from '@@formsHandlers/declareSinistreDeces/isNotForInsured';


const formData = [

{
name: 'title',
required: true,
input: 'select',
source: 'titles',
label: "Civilité",
placeholder: "Civilité",



handlers: {
visible: undefined
}
},

{
name: 'birthname',
required: true,
input: 'text',
source: '__UNDEFINED__',
label: "Nom de naissance",
placeholder: "__UNDEFINED__",



handlers: {
visible: undefined
}
},

{
name: 'firstname',
required: true,
input: 'text',
source: '__UNDEFINED__',
label: "Prénom(s)",
placeholder: "__UNDEFINED__",



handlers: {
visible: undefined
}
},

{
name: 'birthdate',
required: true,
input: 'date',
source: '__UNDEFINED__',
label: "Date de naissance",
placeholder: "__UNDEFINED__",



handlers: {
visible: undefined
}
},

{
name: 'nir',
required: true,
input: 'text',
source: '__UNDEFINED__',
label: "N° sécurité sociale",
placeholder: "__UNDEFINED__",



handlers: {
visible: undefined
}
},

{
name: 'forInsured',
required: false,
input: 'radio',
source: 'yesNoType',
label: "Le sinistre décès concerne-t-il l'assuré lui même ?",
placeholder: "__UNDEFINED__",



handlers: {
visible: undefined
}
},

{
name: 'decease_at',
required: false,
input: 'date',
source: '__UNDEFINED__',
label: "Décédé le",
placeholder: "__UNDEFINED__",



handlers: {
visible: isForInsured
}
},

{
name: 'iad_at',
required: false,
input: 'date',
source: '__UNDEFINED__',
label: "ou atteint d’une invalidité absolue et définitive depuis le",
placeholder: "__UNDEFINED__",



handlers: {
visible: isForInsured
}
},

{
name: 'family_situation',
required: true,
input: 'select',
source: 'familySituation',
label: "Situation familiale",
placeholder: "Situation familiale",



handlers: {
visible: isForInsured
}
},

{
name: 'childrens',
required: true,
input: 'number',
source: '__UNDEFINED__',
label: "Nombre d’enfants à charge au sens fiscal",
placeholder: "__UNDEFINED__",



handlers: {
visible: isForInsured
}
},

{
name: 'firstChildBirth',
required: true,
input: 'date',
source: '__UNDEFINED__',
label: "Date(s) de naissance 1er enfant",
placeholder: "__UNDEFINED__",



handlers: {
visible: hasMin1Child
}
},

{
name: 'secondChildBirth',
required: true,
input: 'date',
source: '__UNDEFINED__',
label: "Date(s) de naissance 2eme enfant",
placeholder: "__UNDEFINED__",



handlers: {
visible: hasMin2Child
}
},

{
name: 'thirdChildBirth',
required: true,
input: 'date',
source: '__UNDEFINED__',
label: "Date(s) de naissance 3eme enfant",
placeholder: "__UNDEFINED__",



handlers: {
visible: hasMin3Child
}
},

{
name: 'fourthChildBirth',
required: true,
input: 'date',
source: '__UNDEFINED__',
label: "Date(s) de naissance 4eme enfant",
placeholder: "__UNDEFINED__",



handlers: {
visible: hasMin4Child
}
},

{
name: 'emploiCategory',
required: true,
input: 'select',
source: 'employType',
label: "Catégorie d’emploi",
placeholder: "Catégorie d’emploi",



handlers: {
visible: isForInsured
}
},

{
name: 'startDate',
required: true,
input: 'date',
source: '__UNDEFINED__',
label: "Date d’entrée dans l’entreprise",
placeholder: "__UNDEFINED__",



handlers: {
visible: isForInsured
}
},

{
name: 'salaryTrimestrial',
required: true,
input: 'number',
source: '__UNDEFINED__',
label: "Salaire trimestriel brut que l’assuré a perçu au cours du trimestre civil précédant le décès ou l’incapacité de travail (hors primes, compléments de salaire, heures supplémentaires)",
placeholder: "__UNDEFINED__",



handlers: {
visible: isForInsured
}
},

{
name: 'primesLastYear',
required: true,
input: 'number',
source: '__UNDEFINED__',
label: "Montant des primes, gratifications, compléments de salaire et heures supplémentaires versés au cours des 4 trimestres civils précédant le décès ou l’incapacité de travail",
placeholder: "__UNDEFINED__",



handlers: {
visible: isForInsured
}
},

{
name: 'notAffiliateTitle',
required: true,
input: 'select',
source: 'titles',
label: "Civilité",
placeholder: "Civilité",



handlers: {
visible: isNotForInsured
}
},

{
name: 'notAffiliateBirthname',
required: true,
input: 'text',
source: '__UNDEFINED__',
label: "Nom de naissance",
placeholder: "__UNDEFINED__",



handlers: {
visible: isNotForInsured
}
},

{
name: 'notAffiliateFirstname',
required: true,
input: 'text',
source: '__UNDEFINED__',
label: "Prénom(s)",
placeholder: "__UNDEFINED__",



handlers: {
visible: isNotForInsured
}
},

{
name: 'notAffiliateParentLink',
required: true,
input: 'select',
source: 'affiliateRelations',
label: "Lien de parenté avec le salarié",
placeholder: "__UNDEFINED__",



handlers: {
visible: isNotForInsured
}
},

{
name: 'notAffiliateDeceaseAt',
required: true,
input: 'date',
source: '__UNDEFINED__',
label: "Décédé le",
placeholder: "__UNDEFINED__",



handlers: {
visible: isNotForInsured
}
},

{
name: 'contactCivility',
required: false,
input: 'select',
source: 'titles',
label: "Civilité du contact",
placeholder: "Civilité du contact",



handlers: {
visible: undefined
}
},

{
name: 'contactBirthname',
required: false,
input: 'text',
source: '__UNDEFINED__',
label: "Nom de naissance",
placeholder: "__UNDEFINED__",



handlers: {
visible: undefined
}
},

{
name: 'contactFirstname',
required: false,
input: 'text',
source: '__UNDEFINED__',
label: "Prénom(s)",
placeholder: "__UNDEFINED__",



handlers: {
visible: undefined
}
},

{
name: 'contactBirthdate',
required: false,
input: 'date',
source: '__UNDEFINED__',
label: "Date de naissance",
placeholder: "__UNDEFINED__",



handlers: {
visible: undefined
}
},

{
name: 'contactParentLink',
required: false,
input: 'select',
source: 'affiliateRelations',
label: "Lien de parenté du contact avec le defunt",
placeholder: "__UNDEFINED__",



handlers: {
visible: undefined
}
},

{
name: 'contactPhone',
required: false,
input: 'text',
source: '__UNDEFINED__',
label: "Téléphone",
placeholder: "__UNDEFINED__",



handlers: {
visible: undefined
}
},

{
name: 'contactEmail',
required: false,
input: 'text',
source: '__UNDEFINED__',
label: "Email",
placeholder: "__UNDEFINED__",



handlers: {
visible: undefined
}
},

{
name: 'attachments',
required: false,
input: 'files',
source: 'listAttachments',
label: "__UNDEFINED__",
placeholder: "Ajouter des pièces jointes",



handlers: {
visible: undefined
}
},

];

export default formData;