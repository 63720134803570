/**
*
* WARNING : fichier généré par forms, ne pas le modifier directement
*
**/



const formData = [

{
name: 'contractId',
required: true,
input: 'select',
source: 'externalContractIds',
label: "n° de contrat",
placeholder: "n° de contrat",



handlers: {
visible: undefined
}
},

{
name: 'firstname',
required: false,
input: 'text',
source: '__UNDEFINED__',
label: "Prénom",
placeholder: "__UNDEFINED__",

disabled: true,

handlers: {
visible: undefined
}
},

{
name: 'lastname',
required: false,
input: 'text',
source: '__UNDEFINED__',
label: "Nom",
placeholder: "__UNDEFINED__",

disabled: true,

handlers: {
visible: undefined
}
},

{
name: 'title',
required: true,
input: 'select',
source: 'titles',
label: "Civilité",
placeholder: "Civilité",



handlers: {
visible: undefined
}
},

{
name: 'postCode',
required: true,
input: 'text',
source: '__UNDEFINED__',
label: "Code postal",
placeholder: "__UNDEFINED__",



handlers: {
visible: undefined
}
},

{
name: 'address',
required: true,
input: 'text',
source: '__UNDEFINED__',
label: "Adresse",
placeholder: "__UNDEFINED__",



handlers: {
visible: undefined
}
},

{
name: 'city',
required: true,
input: 'text',
source: '__UNDEFINED__',
label: "Ville",
placeholder: "__UNDEFINED__",



handlers: {
visible: undefined
}
},

{
name: 'nir',
required: true,
input: 'text',
source: '__UNDEFINED__',
label: "N° de sécurité sociale",
placeholder: "__UNDEFINED__",



handlers: {
visible: undefined
}
},

{
name: 'reason',
required: true,
input: 'select',
source: 'reasons',
label: "Motif",
placeholder: "__UNDEFINED__",



handlers: {
visible: undefined
}
},

{
name: 'birthDate',
required: true,
input: 'date',
source: '__UNDEFINED__',
label: "Date de naissance",
placeholder: "__UNDEFINED__",



handlers: {
visible: undefined
}
},

{
name: 'releaseDate',
required: true,
input: 'date',
source: '__UNDEFINED__',
label: "Date de sortie de l'effectif",
placeholder: "__UNDEFINED__",



handlers: {
visible: undefined
}
},

];

export default formData;