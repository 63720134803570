/**
*
* WARNING : fichier généré par forms, ne pas le modifier directement
*
**/



const formData = [

{
name: 'contract',
required: true,
input: 'select',
source: 'contractList',
label: "Contrat concerné",
placeholder: "__UNDEFINED__",



handlers: {
visible: undefined
}
},

{
name: 'subject',
required: true,
input: 'select',
source: 'exchangeSubjects',
label: "Votre demande concerne",
placeholder: "Votre demande concerne",



handlers: {
visible: undefined
}
},

{
name: 'message',
required: true,
input: 'textarea',
source: '__UNDEFINED__',
label: "Votre message",
placeholder: "__UNDEFINED__",



handlers: {
visible: undefined
}
},

{
name: 'attachments',
required: false,
input: 'files',
source: '__UNDEFINED__',
label: "__UNDEFINED__",
placeholder: "Joindre un fichier",



handlers: {
visible: undefined
}
},

];

export default formData;