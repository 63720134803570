/**
*
* WARNING : fichier généré par forms, ne pas le modifier directement
*
**/


import codeSouscriptionVisibleField from '@@formsHandlers/apporteurSignUpRequest/codeSouscriptionVisibleField';


const formData = [

{
name: 'userLogin',
required: true,
input: 'text',
source: '__UNDEFINED__',
label: "Identifiant",
placeholder: "Identifiant",



handlers: {
visible: undefined
}
},

{
name: 'name',
required: true,
input: 'text',
source: '__UNDEFINED__',
label: "Nom",
placeholder: "Nom",



handlers: {
visible: undefined
}
},

{
name: 'email',
required: true,
input: 'email',
source: '__UNDEFINED__',
label: "Email",
placeholder: "Email",



handlers: {
visible: undefined
}
},

{
name: 'codeApporteur',
required: true,
input: 'text',
source: '__UNDEFINED__',
label: "Code apporteur",
placeholder: "Code apporteur",



handlers: {
visible: undefined
}
},

{
name: 'isManager',
required: false,
input: 'switch',
source: '__UNDEFINED__',
label: "Manager",
placeholder: "Manager",



handlers: {
visible: undefined
}
},

{
name: 'codeManager',
required: false,
input: 'text',
source: '__UNDEFINED__',
label: "Code du manager",
placeholder: "Code du manager",



handlers: {
visible: codeSouscriptionVisibleField
}
},

];

export default formData;