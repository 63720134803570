/**
*
* WARNING : fichier généré par forms, ne pas le modifier directement
*
**/



const formData = [

{
name: 'gender',
required: true,
input: 'select',
source: 'genders',
label: "Civilité",
placeholder: "Civilité",



handlers: {
visible: undefined
}
},

{
name: 'date',
required: true,
input: 'date',
source: '__UNDEFINED__',
label: "Début d’affiliation",
placeholder: "__UNDEFINED__",



handlers: {
visible: undefined
}
},

{
name: 'firstname',
required: true,
input: 'text',
source: '__UNDEFINED__',
label: "Prénom de l'affilié",
placeholder: "__UNDEFINED__",



handlers: {
visible: undefined
}
},

{
name: 'nir',
required: true,
input: 'text',
source: '__UNDEFINED__',
label: "Numéro Sécurité sociale",
placeholder: "__UNDEFINED__",



handlers: {
visible: undefined
}
},

{
name: 'lastname',
required: true,
input: 'text',
source: '__UNDEFINED__',
label: "Nom de l'affilié",
placeholder: "__UNDEFINED__",



handlers: {
visible: undefined
}
},

{
name: 'contract',
required: true,
input: 'select',
source: '__UNDEFINED__',
label: "Numéro de contrat",
placeholder: "Numéro de contrat",



handlers: {
visible: undefined
}
},

];

export default formData;