/**
*
* WARNING : fichier généré par forms, ne pas le modifier directement
*
**/



const formData = [

{
name: 'title',
required: false,
input: 'select',
source: 'titles',
label: "Civilité",
placeholder: "Civilité",

disabled: true,

handlers: {
visible: undefined
}
},

{
name: 'firstname',
required: false,
input: 'text',
source: '__UNDEFINED__',
label: "Prénom",
placeholder: "__UNDEFINED__",

disabled: true,

handlers: {
visible: undefined
}
},

{
name: 'lastname',
required: false,
input: 'text',
source: '__UNDEFINED__',
label: "Nom",
placeholder: "__UNDEFINED__",

disabled: true,

handlers: {
visible: undefined
}
},

{
name: 'siret',
required: false,
input: 'text',
source: '__UNDEFINED__',
label: "Siret",
placeholder: "__UNDEFINED__",

disabled: true,

handlers: {
visible: undefined
}
},

{
name: 'banner',
required: false,
input: 'text',
source: '__UNDEFINED__',
label: "Raison sociale",
placeholder: "__UNDEFINED__",

disabled: true,

handlers: {
visible: undefined
}
},

{
name: 'fiscalDate',
required: false,
input: 'date',
source: '__UNDEFINED__',
label: "Date clôture fiscale",
placeholder: "__UNDEFINED__",



handlers: {
visible: undefined
}
},

];

export default formData;