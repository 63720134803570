/**
*
* WARNING : fichier généré par forms, ne pas le modifier directement
*
**/


import ShowTiers from '@@formsHandlers/declareSinistreInvalidite/ShowTiers';

import ShowDetailReason from '@@formsHandlers/declareSinistreInvalidite/ShowDetailReason';

import ShowPoliceInvestigation from '@@formsHandlers/declareSinistreInvalidite/ShowPoliceInvestigation';


const formData = [

{
name: 'title',
required: true,
input: 'select',
source: 'titles',
label: "Civilité",
placeholder: "Civilité",



handlers: {
visible: undefined
}
},

{
name: 'birthname',
required: true,
input: 'text',
source: '__UNDEFINED__',
label: "Nom de naissance",
placeholder: "__UNDEFINED__",



handlers: {
visible: undefined
}
},

{
name: 'firstname',
required: true,
input: 'text',
source: '__UNDEFINED__',
label: "Prénom(s)",
placeholder: "__UNDEFINED__",



handlers: {
visible: undefined
}
},

{
name: 'address',
required: true,
input: 'text',
source: '__UNDEFINED__',
label: "Adresse",
placeholder: "__UNDEFINED__",



handlers: {
visible: undefined
}
},

{
name: 'postal',
required: true,
input: 'text',
source: '__UNDEFINED__',
label: "Code postal",
placeholder: "__UNDEFINED__",



handlers: {
visible: undefined
}
},

{
name: 'city',
required: true,
input: 'text',
source: '__UNDEFINED__',
label: "Ville",
placeholder: "__UNDEFINED__",



handlers: {
visible: undefined
}
},

{
name: 'birthdate',
required: true,
input: 'date',
source: '__UNDEFINED__',
label: "Date de naissance",
placeholder: "__UNDEFINED__",



handlers: {
visible: undefined
}
},

{
name: 'nir',
required: true,
input: 'text',
source: '__UNDEFINED__',
label: "N° sécurité sociale",
placeholder: "__UNDEFINED__",



handlers: {
visible: undefined
}
},

{
name: 'dateWorkStopping',
required: true,
input: 'date',
source: '__UNDEFINED__',
label: "date du début de l’arrêt de travail précédent l’invalidité",
placeholder: "__UNDEFINED__",



handlers: {
visible: undefined
}
},

{
name: 'workStoppingReason',
required: true,
input: 'select',
source: 'workStoppingReasonType',
label: "Motif de l’arrêt de travail",
placeholder: "Motif de l’arrêt de travail",



handlers: {
visible: undefined
}
},

{
name: 'invalidityDate',
required: true,
input: 'date',
source: '__UNDEFINED__',
label: "Date de mise en invalidité",
placeholder: "__UNDEFINED__",



handlers: {
visible: undefined
}
},

{
name: 'accidentTiers',
required: false,
input: 'radio',
source: 'yesNoType',
label: "Si un arrêt de travail est consécutif à un accident, a-t-il été causé par un tiers responsable ?",
placeholder: "__UNDEFINED__",
columns: 2,


handlers: {
visible: undefined
}
},

{
name: 'genderTiers',
required: false,
input: 'select',
source: 'titles',
label: "Civilité du Tiers",
placeholder: "Civilité",



handlers: {
visible: ShowTiers
}
},

{
name: 'birthnameTiers',
required: false,
input: 'text',
source: '__UNDEFINED__',
label: "Nom de naissance du Tiers",
placeholder: "__UNDEFINED__",



handlers: {
visible: ShowTiers
}
},

{
name: 'firstnameTiers',
required: false,
input: 'text',
source: '__UNDEFINED__',
label: "Prénom(s) du Tiers",
placeholder: "__UNDEFINED__",



handlers: {
visible: ShowTiers
}
},

{
name: 'addressTiers',
required: false,
input: 'text',
source: '__UNDEFINED__',
label: "Adresse du Tiers",
placeholder: "__UNDEFINED__",



handlers: {
visible: ShowTiers
}
},

{
name: 'postalTiers',
required: false,
input: 'text',
source: '__UNDEFINED__',
label: "Code postal du Tiers",
placeholder: "__UNDEFINED__",



handlers: {
visible: ShowTiers
}
},

{
name: 'cityTiers',
required: false,
input: 'text',
source: '__UNDEFINED__',
label: "Ville du Tiers",
placeholder: "__UNDEFINED__",



handlers: {
visible: ShowTiers
}
},

{
name: 'dateAccident',
required: false,
input: 'date',
source: '__UNDEFINED__',
label: "Date de l'accident",
placeholder: "__UNDEFINED__",



handlers: {
visible: ShowTiers
}
},

{
name: 'reasonAccident',
required: false,
input: 'select',
source: 'accidentReason',
label: "Lieu de l'accident",
placeholder: "__UNDEFINED__",



handlers: {
visible: ShowTiers
}
},

{
name: 'reasonAccidentOther',
required: false,
input: 'text',
source: '__UNDEFINED__',
label: "Précisez",
placeholder: "__UNDEFINED__",



handlers: {
visible: ShowDetailReason
}
},

{
name: 'detailAccident',
required: false,
input: 'textarea',
source: '__UNDEFINED__',
label: "Circonstances détaillées de l'accident",
placeholder: "__UNDEFINED__",



handlers: {
visible: ShowTiers
}
},

{
name: 'assurSociety',
required: false,
input: 'text',
source: '__UNDEFINED__',
label: "Raison sociale de l'assureur du tiers responsable",
placeholder: "__UNDEFINED__",
columns: 2,


handlers: {
visible: ShowTiers
}
},

{
name: 'assurAddress',
required: false,
input: 'text',
source: '__UNDEFINED__',
label: "Adresse de l'assureur tiers",
placeholder: "__UNDEFINED__",



handlers: {
visible: ShowTiers
}
},

{
name: 'assurPostal',
required: false,
input: 'text',
source: '__UNDEFINED__',
label: "Code postal de l'assureur tiers",
placeholder: "__UNDEFINED__",



handlers: {
visible: ShowTiers
}
},

{
name: 'assurCity',
required: false,
input: 'text',
source: '__UNDEFINED__',
label: "Ville de l'assureur tiers",
placeholder: "__UNDEFINED__",



handlers: {
visible: ShowTiers
}
},

{
name: 'folderTiers',
required: false,
input: 'text',
source: '__UNDEFINED__',
label: "Le numéro de dossier auprès de sa compagnie d’assurance (si connu)",
placeholder: "__UNDEFINED__",



handlers: {
visible: ShowTiers
}
},

{
name: 'hasPoliceInvestigation',
required: false,
input: 'radio',
source: 'yesNoType',
label: "Une enquête de police ou de gendarmerie a-t-elle eu lieu ?",
placeholder: "__UNDEFINED__",



handlers: {
visible: ShowTiers
}
},

{
name: 'procesVerbal',
required: false,
input: 'text',
source: '__UNDEFINED__',
label: "Numéro de procès verbal",
placeholder: "__UNDEFINED__",



handlers: {
visible: ShowPoliceInvestigation
}
},

{
name: 'rib',
required: false,
input: 'select',
source: 'ribs',
label: "RIB de l'assuré",
placeholder: "__UNDEFINED__",



handlers: {
visible: undefined
}
},

{
name: 'invalidityRegime',
required: true,
input: 'files',
source: '__UNDEFINED__',
label: "reconnaissance d'invalidité du régime obligatoire",
placeholder: "Joindre un fichier",



handlers: {
visible: undefined
}
},

{
name: 'taxNotice',
required: true,
input: 'files',
source: '__UNDEFINED__',
label: "avis imposition de l'année N-1",
placeholder: "Joindre un fichier",



handlers: {
visible: undefined
}
},

{
name: 'paymentStatement',
required: true,
input: 'files',
source: '__UNDEFINED__',
label: "décompte de paiement du régime obligatoire",
placeholder: "Joindre un fichier",



handlers: {
visible: undefined
}
},

];

export default formData;